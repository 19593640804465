import { useLocation } from "react-router-dom";
import PortfolioTable from "../components/Portfolio/PortfolioTable";
import PortfolioChart from "../components/Portfolio/PortfolioChart";
import { useEffect, useState } from "react";
import { getPortfolio } from "../network/TableApi/tableApi";
import { SinglePortfolioRes } from "../network/TableApi/tableInterfaces";

interface IProps {}

const PortfolioPage = (props: IProps) => {
  const [name, setName] = useState<string>("Название портфеля");
  const [percMode, setPercMode] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null); // Добавлено состояние ошибки
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const id = searchParams.get("id");

  useEffect(() => {
    if (!id) {
      setError("ID портфеля не задан");
      return;
    }

    getPortfolio({ id: id })
      .then((res: SinglePortfolioRes) => {
        setName(res.data.name || "");
        setPercMode(res.data.isOnlyPerc);
        setError(null); // Сбрасываем ошибку при успешной загрузке
      })
      .catch((err) => {
        console.error("Ошибка загрузки портфеля:", err);
        setError("Портфель не найден");
      });
  }, [id]);

  if (error) {
    return (
      <div className="defaultLayout">
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="defaultLayout">
      <div className="mb-4">
        <p>
          Портфель <b>{name}</b>
        </p>
      </div>
      {id && (
        <div>
          <PortfolioTable id={id} external={true} percMode={percMode} />
          <div className="flex flex-col mt-10">
            <p className="mb-4">Графики за все время</p>
            <PortfolioChart
              id={id}
              type={"amountCompound"}
              desc="Сложный процент"
            />
            <PortfolioChart
              id={id}
              type={"factInc"}
              desc="Фактический процент"
            />
            {!percMode && (
              <div>
                <PortfolioChart id={id} type={"amountMins"} desc="Заработано" />
                <PortfolioChart id={id} type={"balanceBefore"} desc="Баланс" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PortfolioPage;
