import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import { NavLink } from "../types/component";
import AppRoutes from "../structure/routes";
import Header from "./Header";

interface IProps {}
const navLinks: Array<NavLink> = [
  { linkName: "Регистрация", appRoute: AppRoutes.RegisterPage },
  { linkName: "Информация", appRoute: AppRoutes.RegisterPage },
];
const PublicLayout = (props: IProps) => {
  return (
    <div className="flex flex-col items-center">
      <Header />
      <div className="flex items-center">
        <div className="relative p-6 flex gap-6">
          <div>
            <NavBar navLinks={navLinks} external={true} />
          </div>
          <div className="w-[900px] pb-[80px] ">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicLayout;
