import { useEffect, useState } from "react";
import {
  AccountMarginAndDrawdown,
  GetMarginAndDrawdownRes,
} from "../../network/SumTableApi/SumTableInterfaces";
import { getMarginAndDrawdown } from "../../network/SumTableApi/SumTableApi";
import YearlyDrawdown from "../YearlyDrawdown";
import YearlyMargin from "../YearlyMargin";

interface IProps {
  accounts: string[];
}

const MarginAndDrawdown = (props: IProps) => {
  const [tableData, setTableData] = useState<AccountMarginAndDrawdown[]>([]);

  useEffect(() => {
    getMarginAndDrawdown({ accounts: props.accounts })
      .then((res: GetMarginAndDrawdownRes) => {
        setTableData(res.data);
      })
      .catch(() => {});
  }, []);
  return (
    <div>
      <YearlyMargin
        accounts={props.accounts}
        data={tableData}
        selectedYear={2024}
      />
      <YearlyDrawdown
        accounts={props.accounts}
        data={tableData}
        selectedYear={2024}
      />
    </div>
  );
};

export default MarginAndDrawdown;
